<template>
  <b-overlay :show="loading">
    <b-alert
      variant="danger"
      :show="blogPost === undefined && !loading"
    >
      <h4 class="alert-heading">
        Error fetching Blog Post Details
      </h4>
      <div class="alert-body">
        <b-link
          class="alert-link"
          :to="{ name: 'admin-blogs'}"
        >
          List Blogs
        </b-link>
      </div>
    </b-alert>

    <add-or-update-form 
      v-if="blogPost"
      ref="blogPostForm" 
      :updating-blog="blogPost"
      @update="onUpdateBlogPost" 
    />
  </b-overlay>
</template>

<script>
import { BOverlay, BAlert, BLink } from "bootstrap-vue"
import { get } from "lodash"

import AddOrUpdateForm from "@/@core/components/shared/admin/blogs/AddOrUpdateForm.vue"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BOverlay,
    BAlert,
    BLink,

    AddOrUpdateForm,
  },
  directives: {},
  data() {
    return {
      loading: false,
      blogPost: null
    }
  },
  created(){
    this.fetchBlogPost()
  },
  methods: {
    async fetchBlogPost() {
      try {
        this.loading = true; 

        const { id } = this.$route.params;

        const request = await this.useJwt().sharedService.fetchBlogPost(id);
        const { data } = request.data;

        this.blogPost = data;
      } catch (error) {
        this.blogPost = undefined
        const error_message = get(error, "response.data.message") || error.message
        console.log("error_message", error_message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false;
      }
    },
    async onUpdateBlogPost(updateForm){
      try {
        this.loading = true;

        await this.useJwt().adminService.updateBlogPost(this.blogPost._id, updateForm);
        this.$refs.blogPostForm.resetForm();
    
        await this.$router.push({
          name: "admin-blogs"
        });

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckCircleIcon',
            variant: 'success',
            text: `Blog Post updated successfully`
          },
        });
      } catch (error){
        const error_message = get(error, "response.data.message") || error.message

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: error_message
          },
        });
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/pages/page-blog.scss';
</style>
